.section-store-locator {
  &.device-pc {
    .store-locator {
      .local-search-form {
        &__inner {
          .local-search-form__city {
            width: 100% !important;
          }
        }
      }
    }
  }
  .doors-results__pager {
    &:lang(ar-ME) {
      .previous,
      .next {
        img {
          transform: rotate(180deg);
        }
      }
    }
  }
}
