@media only screen and (max-width: 330px) {
  a.product-brief__button-quickshop {
    line-height: 44px;
    padding: 0 2px;
  }
}
/* font style */
@font-face {
  font-family: 'Conv_OptimaEL-Text';
  src: url('#{$netstorage-font-path}OptimaDisplay/OptimaDisplay-Roman.woff2') format('woff2');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

.header__headline,
.mpp__header,
.mpp__bestfor--headline,
.mb-slideshow__slide-content h2 {
  font-family: 'Optima Display Roman' !important;
}

.brand-renutriv {
  .mpp__header,
  .header__headline {
    font-family: $bb-roman !important;
  }
}

.footer-social-links {
  display: none;
}

.page-sticky-footer.is-sticky {
  overflow: scroll;
}
