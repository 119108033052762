@mixin gluxre_update_core {
  .page-header {
    .page-branding__logo {
      @media #{$medium-up} {
        position: static;
      }
    }
  }
  .section-aerin,
  #{$aerin} {
    .page-header {
      .page-branding__logo {
        @media #{$medium-up} {
          position: static;
        }
      }
    }
  }
  .section-re-nutriv,
  #{$renutriv} {
    .page-header {
      .page-navigation__menu-icon {
        background-image: url('/sites/esteelauder/themes/core_base/img-local/sprites-s24fb7978d7.png');
        background-repeat: no-repeat;
      }
    }
  }
  .page-branding__logo {
    .gnav-active #{$alt} & {
      background-size: 187px 27px;
      width: 187px;
      height: 27px;
    }
  }
  .gnav-active,
  .mobile-gnav-active {
    &.section-aerin,
    &#{$aerin} {
      #{$alt} {
        .page-branding__logo {
          background-image: url('/media/export/cms/gnav/elc-logo-core.png');
          background-repeat: no-repeat;
          background-position: 0 0;
          width: 187px;
          height: 27px;
          background-size: 187px 27px;
        }
      }
    }
    .page-header#{$alt} {
      .page-branding__logo {
        @media #{$medium-up} {
          position: static;
          margin-#{$ldirection}: 0;
        }
      }
    }
  }
  .gnav-active {
    @media #{$medium-up} {
      &.section-re-nutriv,
      &#{$renutriv} {
        #{$alt},
        #{$dark-nav} {
          .page-branding__logo {
            background-image: url('/media/export/cms/gnav/logo_estee-lauder_white.png');
            background-repeat: no-repeat;
            background-position: 0 0;
            width: 187px;
            height: 26px;
            background-size: 187px 26px;
          }
        }
      }
    }
  }
  .page-navigation {
    &.active-menu-toogle {
      clear: both;
      margin-top: -3px;
      cursor: default;
    }
  }
  .mobile-gnav-active {
    #{$alt} {
      .page-navigation {
        &__menu-toggle {
          text-align: center;
          width: 45px;
          height: 52px;
          position: absolute;
          #{$ldirection}: 0;
          top: 0;
          border-#{$rdirection}: 1px solid $color-light-gray;
          z-index: 30;
        }
      }
    }
    &.section-re-nutriv,
    &#{$renutriv} {
      #{$alt} {
        .page-navigation__menu-icon {
          background-image: url(/media/export/cms/gnav/gnav_icons.png);
          background-repeat: no-repeat;
          background-size: auto auto;
          background-position: 3px 2px;
          height: 20px;
          width: 21px;
        }
        .menu-ref {
          .menu--lvl-2,
          .menu--lvl-4 {
            a {
              color: $color-navy;
            }
          }
        }
      }
    }
  }
  .utility-nav {
    &__signup {
      &-form {
        @include swap_direction(padding, 9px 22px 0 0);
        height: 52px;
      }
      &-error {
        font-size: 12px;
      }
      &-submit {
        height: 32px;
        width: auto;
        line-height: 30px;
        padding: 0 19px;
        background: none;
        border: 1px solid $color-navy;
        color: $color-navy;
        font-size: 12px;
        .section-re-nutriv &,
        #{$renutriv} & {
          border: 1px solid $color-medium-gray;
          color: $color-white;
        }
        #{$alt} & {
          @include input-placeholder {
            color: $color-navy;
          }
          background-color: $color-white;
          color: $color-navy;
          border: 1px solid $color-navy;
          .section-re-nutriv &,
          #{$renutriv} & {
            background-color: $color-black;
            border: 1px solid $color-white;
            color: $color-white;
          }
        }
      }
      input[type='email'],
      &-email {
        @include input-placeholder {
          color: $color-navy;
        }
        border: 1px solid $color-navy;
        height: 32px;
        padding: 5px 19px;
        color: $color-navy;
        font-size: 12px;
        width: 169px;
        margin-bottom: 10px;
        background: transparent;
        float: none;
        #{$alt} & {
          @include input-placeholder {
            color: $color-navy;
          }
          border: 1px solid $color-navy;
          background-color: $color-white;
          color: $color-navy;
          .section-re-nutriv &,
          #{$renutriv} & {
            @include input-placeholder {
              color: $color-white;
            }
            color: $color-white;
          }
        }
        .section-re-nutriv &,
        #{$renutriv} & {
          @include input-placeholder {
            color: $color-white;
            background-color: transparent;
          }
          border: 1px solid $color-medium-gray;
          color: $color-white;
          background-color: $color-black;
          box-shadow: none;
        }
      }
      &-email {
        background: transparent;
        opacity: 1;
      }
    }
  }
  .mpp {
    .mpp__header {
      margin-top: 15px;
    }
    &__bestfor {
      margin-top: 15px;
    }
  }
}

@if $gluxre_update == true {
  @include gluxre_update_core;
}
