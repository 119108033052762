.gnav-active,
.mobile-gnav-active {
  &.section-aerin,
  &#{$aerin} {
    #{$alt} {
      .page-branding__logo {
        background-image: url('/media/export/cms/gnav/aerin_logo.png');
        background-color: transparent;
        background-size: auto;
        background-repeat: no-repeat;
        background-position: 0 0;
        width: 168px;
        height: 34px;
      }
    }
  }
}
.section-aerin,
&#{$aerin} {
  .page-header {
    &:not(#{$alt}) {
      .page-navigation__menu-icon {
        background-position: 0 -4637px;
      }
    }
  }
}
